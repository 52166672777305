<template>
  <v-card rounded="xl">
    <v-card flat link :class="`main-${subject.color}-bg`">
      <v-card-text
        class="d-flex justify-center align-center"
        @click="selectSubject(subject)"
      >
        <v-sheet
          :class="`d-flex justify-center align-center rounded-circle secondary-${subject.color}-bg`"
          elevation="5"
          height="90"
          width="90"
        >
          <div>
            <v-icon
              v-if="subject.iconType == 'font'"
              :class="`main-${subject.color}-text`"
              >mdi-{{ subject.icon }}</v-icon
            >
            <v-img v-else contain :src="subject.image"></v-img>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
    <v-card-title>
      <v-tooltip :open-on-hover="false" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="text-truncate">{{
            subject.name
          }}</span>
        </template>
        <span>{{ subject.name }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>{{ `GRADE : ${subject.gradeLevel}` }} </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "SubjectCard",
  props: {
    path: {
      type: String,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectSubject(subject) {
      let quarterOptions = [];
      if (subject.semester) {
        switch (subject.semester) {
          case 1:
            quarterOptions.push(1, 2);
            break;
          case 2:
            quarterOptions.push(3, 4);
            break;
        }
      } else {
        quarterOptions.push(1, 2, 3, 4);
      }
      this.$store.commit("subjectsModule/setQuarterOptions", quarterOptions);
      this.$store.commit("subjectsModule/setSubject", subject);
      this.$router.push({
        path: `${this.path}/${subject.slug}`,
      });
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
