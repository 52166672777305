<template>
  <div>
    <h2 class="mb-4">Weekly Report</h2>
    <v-row style="position: relative">
      <v-col
        xl="3"
        lg="3"
        md="3"
        sm="6"
        xs="12"
        v-for="weeklyReport in weeklyReports"
        :key="weeklyReport.name"
      >
        <v-card class="mx-auto" outlined rounded="xl">
          <div :class="`module-border ${weeklyReport.color}`">
            <v-list-item three-line>
              <v-icon x-large :class="weeklyReport.color">{{
                weeklyReport.icon
              }}</v-icon>
              <v-list-item-content class="text-center">
                <v-list-item-title class="headline mb-1">
                  <h2>{{ weeklyReport.number }}</h2>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  weeklyReport.name
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WeeklyReport",
  computed: {
    ...mapState({
      weeklyReport: (state) => state.authModule.weeklyReport,
    }),
    weeklyReports() {
      return [
        {
          name: "Lessons",
          number: this.weeklyReport.lesson_count,
          icon: "mdi-bookshelf",
          color: "main-green-text",
        },
        {
          name: "Activities",
          number: this.weeklyReport.activity_count,
          icon: "mdi-notebook-edit-outline",
          color: "main-teal-text",
        },
        {
          name: "Quizzes",
          number: this.weeklyReport.quiz_count,
          icon: "mdi-note-text-outline",
          color: "main-red-text",
        },
        {
          name: "Exams",
          number: this.weeklyReport.exam_count,
          icon: "mdi-square-edit-outline",
          color: "main-blue-text",
        },
      ];
    },
  },
};
</script>

<style>
.module-border {
  border-left-style: solid;
  border-left-width: 6px;
}
</style>
