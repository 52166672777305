<template>
  <v-card class="pa-5" elevation="2">
    <v-card-title class="mb-4">
      <v-row justify="center" justify-sm="space-between">
        <h2>Subjects</h2>
        <SearchQuery
          class="py-4 py-sm-0"
          @search="searchInputQuery = $event"
          style="max-width: 400px"
        />
      </v-row>
    </v-card-title>
    <v-spacer class="py-2"></v-spacer>
    <v-row justify="start" v-if="!subjectsAreYearLong">
      <v-btn-toggle v-model="filterSubjectsByPeriod" mandatory>
        <v-btn
          v-for="(period, index) in periodOptions"
          :key="index"
          :value="period.value"
          text
          class="main-red-text"
          :x-small="$vuetify.breakpoint.xsOnly ? true : false"
          :height="$vuetify.breakpoint.xsOnly ? '30' : ''"
          :large="$vuetify.breakpoint.xsAndUp ? true : false"
          :ripple="false"
        >
          {{ period.label }}
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row>
      <template v-if="filteredSubjects.length > 0">
        <v-col
          xl="3"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          cols="12"
          v-for="subject in filteredSubjects"
          :key="subject.name"
        >
          <SubjectCard :subject="subject" :path="path" />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <v-banner
            two-line
            class="main-red-text secondary-red-bg"
            rounded
            shaped
          >
            <v-icon slot="icon" class="main-red-text" size="36">
              mdi-information-outline
            </v-icon>
            No Subjects Found.
          </v-banner>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SubjectCard from "@/components/SubjectCard";
import SearchQuery from "@/components/SearchQuery";
export default {
  name: "UserSubjects",
  components: {
    SubjectCard,
    SearchQuery,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      periodOptions: [
        { label: "Semester 1", value: 1 },
        { label: "Semester 2", value: 2 },
      ],
      filterSubjectsByPeriod: "",
      searchInputQuery: "",
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.subjectsModule.subjects,
    }),
    subjectsAreYearLong() {
      const areYearLong = (subject) => subject.semester === null;
      if (this.subjects.every(areYearLong)) {
        return true;
      } else {
        return false;
      }
    },
    filteredSubjects() {
      let subjectsByPeriod = [],
        period = this.filterSubjectsByPeriod,
        search = this.searchInputQuery;

      if (this.subjects) {
        if (this.subjectsAreYearLong) {
          subjectsByPeriod = this.subjects;
        } else {
          subjectsByPeriod = this.subjects.filter(
            (subject) => subject.semester == period
          );
        }

        if (search) {
          subjectsByPeriod = subjectsByPeriod.filter((subject) =>
            subject.name.toLowerCase().includes(search.toLowerCase())
          );
        }
      }

      return subjectsByPeriod;
    },
  },
  methods: {},
  watch: {
    subjectsAreYearLong: {
      handler(computedAndTrue) {
        const areSemestral = (subject) => subject.semester !== null,
          subjectsAreSemestral = this.subjects.every(areSemestral);

        if (!computedAndTrue && !subjectsAreSemestral) {
          this.periodOptions = [
            { label: "Year Long", value: null },
            ...this.periodOptions,
          ];
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    const { id, role} = this.$store.state.authModule.user;
    
    await this.$store.dispatch("subjectsModule/getSubjects", {
      role,
      userId: id,
    });
  },
};
</script>

<style></style>
